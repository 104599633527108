<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                          </p>
                        </div>  
                      </div>
                    </div>
                  </div>    

<div class="alert alert-success" role="alert" id="carga">Espere unos segundos! estamos cargando una nuevaproducción</div>

                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
               
              
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="position-relative form-group"><label for="desde" class="">Fecha desde</label><input name="desde" id="desde" type="date" class="form-control"></div>
                  </div>
                  <div class="col-md-6">
                    <div class="position-relative form-group"><label for="hasta" class="">Fecha hasta</label><input name="desde" id="hasta" type="date" class="form-control"></div>
                  </div>
                </div>
            
                
                <div class="form-row" v-for="sabores in sabores"  v-bind:key="sabores.id">
                  <div class="col-md-10">
                    <div class="position-relative form-group" style="background-color: #76828f;padding: 7px;color: #fff;">{{sabores.name}}</div>
                  </div>
                  <div class="col-md-2">
                    <div class="position-relative form-group"><input name="state" v-bind:data-id="sabores.id" v-bind:data-name="sabores.name" type="number" class="form-control sabores" placeholder="0"></div>
                  </div>
               
                </div>
              
                <button class="mt-2 btn btn-primary" v-on:click="guardar()">Guardar</button> 

                <router-link :to="{ name: 'Produccion' }">
                  <button class="mt-2 btn btn-light">Cancelar</button>
                </router-link>
         
               
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>


import axios from 'axios';
import $ from 'jquery'
import firebase from "firebase/app";
import "firebase/database";



export default {
  name: 'Nueva',
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Nueva',
      descripcion:'Crea una nueva produccón',
      sabores:[]
     
   }), 

    mounted: function () {

                 
                 /* --- MOSTRAR REGISTROS ---*/
              
                    axios
                      .get('https://helados-fs.firebaseio.com/sabores.json?print=pretty')
                      .then(response => {
                        
                   
                        this.sabores = response.data;

                      })
                      .catch(error => {
                      
                         console.log(error);
                        

                      });

                  /* --- CIERRA MOSTRAR REGISTROS ---*/    
                    
                

                },
    methods:{
       guardar(){


              $("#carga").slideDown();
              window.scrollTo({ top: 0, behavior: 'smooth' });


      axios
          .get('https://helados-fs.firebaseio.com/sabores.json?print=pretty')
          .then(response => {
            
       
            this.sabores = response.data;

          })
          .catch(error => {
          
             console.log(error);
            

          });


              let desde = window.$("#desde").val();
              let hasta = window.$("#hasta").val();
 /* --- GUARDA REGISTROS ---*/
            
              axios.get('https://helados-fs.firebaseio.com/fechas.json?orderBy=%22$key%22&limitToLast=1&print=pretty')
              .then(function (response) {
                 
                  $.each(response.data,function(k,v){
               console.log(v)
                                       let idx = parseInt(v.id);
                                          let ide = idx+1;
                                        
                                          console.log(ide)

                                    firebase.database().ref('fechas').push({
                                    desde: desde,
                                    hasta:hasta,
                                    id:ide
                                    })
                                    .then(()=>{
                                         
                                          let time = 2000;

                                          setTimeout( function(){  

                                          $( ".sabores" ).each(function(){
                                              let valor = window.$(this).val();
                                              let id = window.$(this).attr("data-id");
                                              let sabor = window.$(this).attr("data-name");
                                             
                                       



                                              setTimeout( function(){  
                                                console.log(id+"  : "+valor+" : "+valor+" : "+ide)
                                               

                                                      if (id==24) {
                                                       location.href='https://produccion.foodservice.com.ar/';
                                                       
                                                      }


if (valor!="") {


                                                firebase.database().ref('produccion').push({
                                                  helados_fecha: {
                                                  desde: desde,
                                                  hasta: hasta,
                                                  id: ide
                                                  },
                                                  helados_sabores: {
                                                  id: id,
                                                  name:sabor,
                                                  },
                                                  litros: valor,
                                                  stock: valor,
                                                  id:id
                                                })
                                                .then(()=>{
                                                    

                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                });


}

                                                

                                              }, time)
                                              time += 2000;

                                          }, 1000)

                                          })
              })
              .catch(e => {
                  console.log(e);
              });
 






                  });

              })

         /* ---  CIERRA GUARDA REGISTROS ---*/    
              

/*
              firebase.database().ref('orientacion-b/').push({
              status: event.target.value,
              })
              .then(()=>{
                  caso.status = event.target.value;
              })
              .catch(e => {
                  console.log(e);
              });
*/

              /* --- GUARDA REGISTROS 
            
              axios.get('https://helados-fs.firebaseio.com/fechas.json', {
                print:'pretty',
                desde:desde,
                hasta:hasta
              })
              .then(function (response) {
               
                const ide = response.data.id;
                let time = 2000;

                setTimeout( function(){  
                
                    $( ".sabores" ).each(function(){
                            let valor = window.$(this).val();
                            let id = window.$(this).attr("data-id");
                           
                            

                            setTimeout( function(){  
                              console.log(id+"  : "+valor+" : "+valor+" : "+ide)

                              axios.post('https://matrix.foodservice.com.ar/helados-produccions/', {
                              "helados_sabores": {"id":id},
                              "helados_fecha": {"id":ide},
                              "litros":valor,
                              "stock":valor
                              })
                              .then(function (responses) {
                                console.log(responses);

                                if (id==14) {
                                 location.href='https://produccion.foodservice.com.ar/';
                                 //alert("ok")
                                }

                              })

                            }, time)
                            time += 2000;

                  }, 1000)



                  });

              })

           CIERRA GUARDA REGISTROS ---*/    

       }
    }          
  
};

</script>

<style>
  #carga{
    display:none;
  }
</style>